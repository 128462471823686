import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { AlertVariants } from '@boels-shared/constants/alerts.const';
import { ContentFit } from './pill.types';

@Component({
  selector: 'bds-pill',
  standalone: true,
  imports: [NgClass],
  templateUrl: './pill.component.html',
  styleUrl: './pill.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillComponent {
  public variant = input<AlertVariants>('neutral');
  public contentFit = input<ContentFit>('hug');

  // Bind the classes to the host element, accessing the signals directly
  @HostBinding('class') private get hostClassBinding(): string {
    return `bds-pill bds-pill--${this.variant()} bds-pill--${this.contentFit()}`;
  }
}
